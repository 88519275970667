@use "../components/_mixins";
@use "../components/_extends";
@use "../components/_colors";
@use "../components/_main";
@use "../components/_whiteHeader";
@use "../components/_tabs";
@use "../components/_modal";
@use "../components/_stickyFooterSearch";

.validation-summary-errors {
    padding: 0 121px;
    margin: 20px 0 0;
    max-width: 600px;
    font-size: 1.4em;   
    ul { margin:0; padding:0; }
    @include mixins.mq(860){padding: 0 80px;}
    @include mixins.mq(540){padding: 0 30px;}
}
span.field-validation-error { margin-top:6px !important; color:red !important; }
.text-danger { color:red !important; }
a.link { font-size:1.4em; }
input.input-validation-error { border:1px solid red !important; }


.login{
    &__header{text-align:center; padding:220px 0 59px;
        h1{@include mixins.font(3.6, 4.5, 120%, 400, 0, white, "Magazine Grotesque"); text-transform: uppercase}
        p{@include mixins.font(1.6, 1.8, 160%, 400, 0, white); margin: 15px auto 0; max-width: 600px;} 
        @media(max-width: 930px){padding-top: 170px;}
        @media(max-width: 760px){padding-top: 140px;}
        @include mixins.mq(580){padding-bottom:40px;}
    }

    &__panel{background-color:white; padding: 0 0 0; box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25); border-radius: 8px; overflow: hidden;
        .tabs{padding: 9px 20px 0; background: var(--color-yellow-light);
            li{line-height:5.7rem;}
            @include mixins.mq(800){padding-top: 9px !important;}
        }
        .swiper{background-color: var(--color-yellow-light);
            .swiper-button-next, .swiper-button-prev{display: none;}
        }

        &-form{padding:61px 121px;
            .form-input{margin-bottom: 30px;
                &>label{@include mixins.font(1.6, 1.6, 140%, 400, 0, black); display:block; margin-bottom: 6px; }
                input[type="text"], input[type="password"], input[type="email"], select, textarea{border: 1px solid colors.$grey7; padding: 12px; width:100%; min-height:58px;  @include mixins.font(1.4, 1.6, 2rem, 400, 0rem);
                    @include mixins.placeholder(){font-size: 1.4rem;}
                }
                input {border-radius: 8px;
                    &:disabled { background-color:var(--color-yellow-light); }
                }
                select{margin-bottom: 10px; background: url("../../images/icons/chevron-down.svg") center right 20px no-repeat; background-size: 18px;}
                small{display:inline-block; margin-top: 10px;}
                .checkbox{margin:53px 0 0;
                    label{margin:0; letter-spacing:-0.06rem; font-size: clamp(1.6rem, 1.6vw, 130%); font-weight: 400; letter-spacing: 0.02rem;}  
                    @include mixins.mq(860){margin-top: 30px;}    
                    &.top{align-items: flex-start;
                        label{margin-top: -4px;}
                    }  
                }
            }
            button{@include mixins.font(1.6, 1.6, 1.6rem, 700, 0rem, var(--color-off-black)); color:white; padding:12px 15px; background-color:var(--color-yellow); border-radius: 8px; border:none; display:block; height: auto;  
                &.danger{background-color: #f91818;}
            }
            @include mixins.mq(860){padding:61px 80px;}
            @include mixins.mq(540){padding:30px 30px;}
        }
        small{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0.06rem, #6C7175); position:relative; cursor:pointer;
            &.link{color:var(--color-off-black);}
            //&:after{content: ""; border-bottom: 1px solid var(--color-off-black); position:absolute; left:0; right:0; bottom:-4px; opacity:.5;}
        }
        span{@include mixins.font(1.4, 1.6, 1.9rem, 400, 0.06rem, var(--color-off-black)); display:block; margin: 25px 0 0;
             small{color:inherit; color:var(--color-off-black); margin-left: 5px; font-size: inherit; text-decoration: underline;
                 &:after{border-color:var(--color-off-black);} 
                 @include mixins.mq(500){display: block; margin-left: 0; margin-top: 4px;}
             }
        }

        .info-panel{
            h2{font-size: clamp(2rem, 2.4vw, 2.4rem); line-height: 130%; letter-spacing: 0; color: #37424a;}
            h3{font-size: clamp(1.6rem, 2vw, 2rem); line-height: 130%; letter-spacing: 0; color: #37424a; margin-top: 15px;}
            p{line-height: 130%;
                &:not(&:last-child){margin-bottom: 3px;}
            }
            hr{margin: 20px 0;}
            &.center{text-align: center;}
        }

        @include mixins.mq(860){padding-top: 0;}
    }       
}

// KUO-3339 / KUO-3337 : Really horrible hacks to try and stop chrome autofilling / remembering the wrong fields
#resetPasswordForm {
    #Email { background:var(--color-yellow-light); }
    #QuestionAnswerFake { background:#fff; }
}

.login-background{padding-bottom: 120px;
    @include mixins.mq(860){padding-bottom: 80px;}
    @include mixins.mq(500){padding-bottom: 60px;}
}

footer{margin-top: 0;}

//main{  
    // &>div:nth-child(6){margin-top: 0;
    //     @media(max-width: 930px){margin-top: 0;}
    //     @media(max-width: 760px){margin-top: 0;}
    //  }
    //  &:has(header.scrolled){
    //     &>div:nth-child(6){margin-top: 0;
    //         @media(max-width: 760px){margin-top: 0px;}
    //     }
    // }

    // &>div:nth-child(4){margin-top: 120px;
    //     @media(max-width: 930px){margin-top: 107px;}
    //     @media(max-width: 760px){margin-top: 80px;}
    // }
    // &:has(header.scrolled){
    //     &>div:nth-child(4){margin-top: 70px;
    //         @media(max-width: 760px){margin-top: 56px;}
    //     }
    // }
//}